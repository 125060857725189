import React, { useEffect, useState } from "react";
import { useSite } from "../../view/Sites/Provider";
import {
  Alert,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import ErrorsList from "../ErrorsList";

const AddAllEmployees = ({ site, handleClose }) => {
  let theme = useTheme();

  const [SitesListDropdown, setSitesListDropdown] = useState([]);
  const [targetSiteId, setTargetSiteId] = useState();
  const [error, setError] = useState(null);

  const {
    state: { Names: Sites },
    fetchSiteNames,
    AddAllEmployees,
    fetchOne,
  } = useSite();

  useEffect(() => {
    fetchSiteNames(site?.company?.id, 0);
  }, [site]);
  useEffect(() => {
    setSitesListDropdown(Sites?.filter((s) => s.id !== site?.id));
  }, [site, Sites]);
  const handleFunction = async () => {
    try {
      await AddAllEmployees(targetSiteId, site?.id);
      handleClose();
      fetchOne(site?.id);
    } catch (error) {
      console.log(error, "error");
      setError({ title: error.errors.message });
    }
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={3}>
      {error ? (
        <Alert severity="error" sx={{ m: 3 }}>
          <ErrorsList error={error} />
        </Alert>
      ) : (
        ""
      )}
      <GroupAddOutlinedIcon sx={{ fontSize: 170, mb: 3 }} />

      <Typography
        id="modal-modal-description"
        sx={{ textAlign: "center", fontWeight: 600, mb: 2 }}
      >
        You can choose a site where you'd like to add its employees.
      </Typography>

      <FormControl sx={{ minWidth: 300, mb: 2 }}>
        <Typography variant="caption" color="textSecondary">
          Select Site
        </Typography>
        <Select
          onChange={(e) => setTargetSiteId(e.target.value)}
          sx={{ fontSize: 12, height: 40, mt: 1 }}
          displayEmpty
        >
          {SitesListDropdown?.map((site) => (
            <MenuItem key={site.id} value={site.id}>
              {site.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        onClick={handleFunction}
        sx={{
          background: theme.color.blackBackground,
          color: "white",
          width: "100%",
          mb: 1.3,
          "&:hover": { background: `${theme.color.black}` },
        }}
      >
        Save
      </Button>
    </Box>
  );
};

export default AddAllEmployees;
