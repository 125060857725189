import React, { useEffect, useState } from "react";
import FilterForm from "./FilterForm";
import { Alert, Box, Typography } from "@mui/material";
import { useOdooInvoices } from "./provider";
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import Status from "./Render/Status";
import { createYupSchema } from "../../utilities/yupSchemaCreator";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Fields from "./Fields";

// yup schema
const yupSchema = Fields.reduce(createYupSchema, {});
const formSchema = Yup.object().shape(yupSchema);

const List = () => {
  const {
    state: { list, page, perPage, count, length },
    dispatch,
    fetchList,
    send,
  } = useOdooInvoices();
  const form = useForm({
    resolver: yupResolver(formSchema),
  });

  const tabs = [{ title: "Odoo Invoices", num: length }];
  const [selectedCols, setSelectedCols] = useState("Odoo Invoices");

  const [error, setError] = useState();
  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };
  const cols = [
    {
      label: "Remark",
      name: "remark",
      headTextLeft: true,
      textLeft: true,
    },
    {
      label: "Partner",
      name: "partner_ref",
    },
    {
      label: "Date",
      name: "date",
    },
    {
      label: "Product",
      name: "product",
    },
    {
      label: "Quantity",
      name: "quantity",
    },
    {
      label: "Price Unit",
      name: "price_unit",
    },
    {
      label: "Quantity",
      name: "quantity",
    },
    {
      label: "Discount",
      name: "discount",
    },
    {
      label: "Status",
      name: "statusId",
      customRender: Status,
    },
  ];
  useEffect(() => {
    if (form.getValues("siteId") > 0)
      fetchList(form.getValues(), page, perPage);
  }, [page, perPage]);
  return (
    <div>
      <Typography variant="Poster" component="h4" mb={2}>
        Select Site
      </Typography>
      <FilterForm form={form} />
      <TabsList
        tabs={tabs}
        setSelectedCols={setSelectedCols}
        selectedCols={selectedCols}
      />
      <Box mb={1}>
        {error && (
          <Alert severity="error" sx={{ width: "fit-content" }}>
            {error}
          </Alert>
        )}
      </Box>
      <CustomTable
        empty={list?.length === 0 ? true : false}
        cols={cols}
        data={list || []}
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
        }}
        actions={{
          reSend: async (id) => {
            try {
              await send(id);
            } catch (e) {
              setError(e.errors.message);
            }
          },
        }}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
        page={page}
        isPagination={true}
      ></CustomTable>
    </div>
  );
};

export default List;
