import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { CalUtcWithFormat } from "../../utilities/functions";

const Context = createContext();

export function useOdooInvoices() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  count: 1,
  page: 1,
  perPage: 10,
  length: 0,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };
    case "set_length":
      return { ...state, length: action.payload };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function OdooInvoicesProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const fetchList = useCallback(
    async (data, page, perPage) => {
      dispatch({ type: "status", payload: "fetching" });
      let headersData = {
        pageNumber: page,
      };
      if (perPage) headersData.pageSize = perPage;
      if (data.fromDate) headersData.fromDate = CalUtcWithFormat(data.fromDate);
      if (data.toDate) headersData.toDate = CalUtcWithFormat(data.toDate);
      if (data.isFailure) headersData.isFailure = data.isFailure;
      const resData = await req(
        `SiteOdooInvoice/List/${data.siteId}`,
        null,
        {},
        true,
        headersData
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data.invoices || [] });
      dispatch({ type: "set_per_page", payload: resData.data.pageSize });
      dispatch({ type: "set_count", payload: resData.data.totalPages });
      dispatch({ type: "set_length", payload: resData.data.totalSize });
    },
    [req, state.page, state.perPage]
  );

  const send = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `SiteOdooInvoice/Send/${id}`,
            null,
            { method: "PUT" },
            true
          );
          dispatch({ type: "edit", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        send,
      }}
    >
      {children}
    </Context.Provider>
  );
}
