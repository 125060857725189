import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSite } from "../Sites/Provider";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Fields from "./Fields";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FilterList } from "@mui/icons-material";
import { useOdooInvoices } from "./provider";
import { useCompany } from "../Companies/Provider";

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 350px);
`;

const FilterForm = ({ form }) => {
  const theme = useTheme();

  const [error, setError] = useState(null);
  const [failure, setFailure] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [loading, setLoading] = useState(false); // Added loading state

  const {
    state: { Names: sites },
    fetchSiteNames: fetchSites,
  } = useSite();

  const {
    state: { page, perPage },
    fetchList,
  } = useOdooInvoices();

  const {
    state: { Names: companies },
    fetchNames: fetchCompanies,
  } = useCompany();

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchSites(companyId, 0);
  }, [fetchSites, companyId]);

  useEffect(() => {
    form.setValue("isFailure", failure);
  }, [failure]);

  const getData = (name) => {
    switch (name) {
      case "companyId":
        return companies;
      case "siteId":
        return sites;
      default:
        return [];
    }
  };

  const onSubmit = async (values) => {
    setError(null);
    if (values.siteId) {
      setLoading(true); // Start loading
      try {
        await fetchList(values, page, perPage);
      } catch (error) {
        setError(error.errors?.message || "An error occurred");
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      setError("You should select a site");
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
      <Box mb={1}>
        {error && (
          <Alert severity="error" sx={{ width: "fit-content", width: "70%" }}>
            {error}
          </Alert>
        )}
      </Box>
      <Box
        sx={{
          background: "#f7f9fb",
          padding: "10px 20px",
          width: "80%",
          mb: 3,
        }}
      >
        <GridGroup>
          {Fields.map((f, index) => (
            <React.Fragment key={index}>
              {f.select ? (
                <FormControl sx={{ minWidth: 120, marginBottom: "20px" }}>
                  <span style={{ fontSize: "12px" }}>{f.label}</span>
                  <Select
                    onChange={(e) => {
                      f.name === "companyId" && setCompanyId(e.target.value);
                    }}
                    inputProps={{
                      "aria-label": "Without label",
                      ...form.register(f.name),
                      autoFocus: true,
                    }}
                    sx={{
                      fontSize: "12px",
                      width: "300px",
                      height: "40px",
                      marginTop: "14px",
                    }}
                  >
                    {getData(f.name).map((site) => (
                      <MenuItem key={site.id} value={site.id}>
                        {site.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : f.checkbox ? (
                <Box
                  display={"flex"}
                  justifyContent={"start"}
                  alignItems={"center"}
                >
                  <Checkbox
                    checked={failure}
                    onChange={(e) => setFailure(e.target.checked)}
                  />
                  <span style={{ fontSize: "12px" }}>Failure</span>
                </Box>
              ) : (
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={"de"}
                >
                  <div>
                    <div style={{ fontSize: "12px", marginBottom: "2px" }}>
                      {f.label}
                    </div>
                    <DateTimePicker
                      onChange={(date) => form.setValue(f.name, date)}
                      sx={{ width: theme.dimensions.width }}
                      inputProps={{
                        ...form.register(f.name),
                        required: true,
                      }}
                      type="datetime-local"
                      slotProps={{
                        textField: {
                          required: f.required,
                          helperText: <p style={{ color: "#d32f2f" }}></p>,
                          size: "small",
                        },
                      }}
                    />
                  </div>
                </LocalizationProvider>
              )}
            </React.Fragment>
          ))}
        </GridGroup>
        <Box display={"flex"} justifyContent={"end"} mt={2}>
          <Button
            className="b"
            variant="contained"
            type="submit"
            disabled={loading} // Disable button when loading
            sx={{
              backgroundColor: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.blackBackground}` },
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : (
              <FilterList />
            )}
            <span>Filter</span>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default FilterForm;
