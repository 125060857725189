import React, { useState, useEffect } from "react";
import { useUserAccount } from "./Provider";
import { useSite } from "../Sites/Provider";
import { useCompany } from "../Companies/Provider";
import { useSubCompany } from "../SubCompanies/Provider";
import { useEmployees } from "../employee/Provider";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import fields from "./fields";
// material ui
import {
  Box,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Button,
  useTheme,
  Card,
  FormControl,
  FormLabel,
  Alert,
  FormHelperText,
  RadioGroup,
  Grid,
  Radio,
} from "@mui/material";
import styled from "styled-components";
// utilities
import { handleResponse } from "../../utilities/functions";
// components
import ErrorsList from "../../ui/ErrorsList";
import { useAuth } from "../../services/auth";
// style
//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
// yup schema
const formSchema = Yup.object().shape({
  displayName: Yup.string()
    .required("this field is required")
    .max(100, "must be less than or equal to 100 character"),
  username: Yup.string()
    .required("this field is required")
    .matches(/^\S*$/, "this field cannot contain spaces")
    .max(100, "must be less than or equal to 100 character"),
  email: Yup.string()
    .required("this field is required")
    .max(100, "must be less than or equal to 100 character")
    .email("Enter Valid Email"),
  password: Yup.string()
    .required("this field is required")
    .matches(
      "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])",
      "your password not strong!!"
    )
    .min(8, "Too Short, a password with at least 8 letters is required!"),
  isActive: Yup.boolean().required("this field is required"),
  companyId: Yup.number(),
  subCompanyId: Yup.number(),
  odooCompanyId: Yup.number(),
  siteId: Yup.number(),
  auditorSiteId: Yup.number(),
  employeeId: Yup.number(),
});

const FormUserAccount = ({
  handleSuccess,
  setUserAccountDisplayName,
  accountType,
}) => {
  // providers
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  let { role, adminOn } = useAuth();
  const { id } = useParams();

  const {
    state: { Names: siteList },
    fetchSiteNames: fetchSites,
  } = useSite();
  const {
    state: { Names: subCompanyList },
    fetchNames: fetchSubCompanies,
  } = useSubCompany();
  const {
    state: { Names: companyList },
    fetchNames: fetchCompanies,
  } = useCompany();
  const {
    state: { list: employeeList, selected: employee },
    fetchList: fetchEmployees,
    fetchOne: fetchEmployee,
  } = useEmployees();
  const { createAdmin, createUser } = useUserAccount();
  const navigate = useNavigate();
  let theme = useTheme();

  const [filteredEmployeeList, setFilteredEmployeeList] = useState(
    employeeList.filter((item) => item?.user === null).map((e) => e)
  );

  const [companyId, setCompanyId] = useState(0);
  const [siteId, setSiteId] = useState(0);
  const [AdminType, setAdminType] = useState(
    role === "Admin" ? "None" : "Sub Company"
  );
  const [RadioHelperText, setRadioHelperText] = useState(
    <>
      You are creating a{" "}
      <strong>{role === "Admin" ? "GENERAL" : "SUB COMPANY"}</strong> admin
    </>
  );
  const [error, setError] = useState(null);

  // use effects
  useEffect(() => {
    fetchSubCompanies(companyId);
  }, [fetchSubCompanies, companyId]);
  useEffect(() => {
    fetchSites(companyId);
  }, [fetchSites, companyId]);
  useEffect(() => {
    if (role === "Admin") fetchCompanies();
  }, [fetchCompanies, role]);
  useEffect(() => {
    fetchEmployees(companyId, 0, 1, 100);
  }, [fetchEmployees, companyId]);
  useEffect(() => {
    setFilteredEmployeeList(employeeList.filter((item) => item?.user === null));
  }, [employeeList, setFilteredEmployeeList]);
  useEffect(() => {
    if (role === "Company") setCompanyId(adminOn?.id);
  }, [setCompanyId, role, adminOn]);
  useEffect(() => {
    if (role === "Site") setSiteId(adminOn?.id);
  }, [setSiteId, role, adminOn]);
  useEffect(() => {
    if (id) fetchEmployee(id);
  }, [id]);
  useEffect(() => {
    if (id && employee) {
      setValue("employeeId", id);
      setValue("companyId", employee?.companyId);
    }
  }, [employee]);

  // on submit
  const onSubmit = async (values) => {
    if (accountType === "User") {
      delete values.companyId;
      delete values.subCompanyId;
      delete values.siteId;
      delete values.odooCompanyId;
      delete values.auditorSiteId;
    } else {
      if (AdminType === "None") {
        values.companyId = 0;
        values.subCompanyId = 0;
        values.siteId = 0;
        values.odooCompanyId = 0;
      } else if (AdminType === "Company") {
        values.subCompanyId = 0;
        values.siteId = 0;
        values.odooCompanyId = 0;
      } else if (AdminType === "Sub Company") {
        values.companyId = 0;
        values.siteId = 0;
        values.odooCompanyId = 0;
      } else if (AdminType === "Site") {
        values.companyId = 0;
        values.subCompanyId = 0;
        values.odooCompanyId = 0;
      } else if (AdminType === "OdooCompany") {
        values.companyId = 0;
        values.subCompanyId = 0;
        values.siteId = 0;
        values.odooCompanyId = companyId;
      } else if (AdminType === "auditorSiteId") {
        values.companyId = 0;
        values.subCompanyId = 0;
        values.odooCompanyId = 0;
        values.siteId = 0;
        values.auditorSiteId = siteId;
      }
    }
    // return;

    try {
      if (accountType === "User") {
        await createUser(values);
        setUserAccountDisplayName(values.displayName);
        reset();
      } else if (accountType === "Admin") {
        await createAdmin(values);
        setUserAccountDisplayName(values.displayName);
        reset();
      }

      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) {
        if (e.errors.message) setError({ title: e.errors.message });
        else setError(e.errors);
      } else if (e.status === 409) setError({ title: e.errors.message });
      else if (e.status === 500) setError({ title: e.errors.message });

      handleResponse(e.errors.status);
    }
  };

  // Fields functions
  const handleChangeRadio = (e) => {
    setAdminType(e.target.value);
    switch (e.target.value) {
      case "None":
        setRadioHelperText(
          <>
            You are creating a <strong>GENERAL</strong> admin
          </>
        );
        return;
      case "Site":
        setRadioHelperText(
          <>
            You are creating a <strong>SITE</strong> admin
          </>
        );
        return;
      case "auditorSiteId":
        setRadioHelperText(
          <>
            You are creating a <strong>AUDITOR SITE</strong> admin
          </>
        );
        return;
      case "Company":
        setRadioHelperText(
          <>
            You are creating a <strong>COMPANY</strong> admin
          </>
        );
        return;
      case "OdooCompany":
        setRadioHelperText(
          <>
            You are creating a <strong>Odoo COMPANY</strong> admin
          </>
        );
        return;
      case "Sub Company":
        setRadioHelperText(
          <>
            You are creating a <strong>SUB COMPANY</strong> admin
          </>
        );
        return;
      default:
        return;
    }
  };
  const handleChangeSelect = (event, name) => {
    if (name === "companyId") {
      setCompanyId(event.target.value);
    } else if (name === "siteId") {
      setSiteId(event.target.value);
    }
  };
  const getValueMenu = (name) => {
    switch (name) {
      case "subCompanyId":
        return "name";
      case "companyId":
        return "name";
      case "siteId":
        return "name";
      case "employeeId":
        return "fullName";
      default:
        return;
    }
  };
  const getSelectData = (name) => {
    switch (name) {
      case "companyId":
        return companyList;
      case "subCompanyId":
        return subCompanyList;
      case "siteId":
        return siteList;
      case "employeeId":
        // only employees that have no user account
        // filteredEmployeeList
        return filteredEmployeeList;
      default:
        return;
    }
  };
  useEffect(() => {
    if (role === "Company") {
      setValue("companyId", adminOn?.id);
    }
  }, [adminOn]);

  return (
    <>
      <Box>
        {/* Body */}
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card
            sx={{
              p: theme.dimensions.padding,
              mt: theme.dimensions.mt,
              mb: theme.dimensions.mb,
            }}
          >
            {error && (
              <Alert severity="error">
                <ErrorsList error={error} />
              </Alert>
            )}
            {accountType === "User" && <Typography>User Details</Typography>}
            {accountType === "Admin" && (
              <Grid>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Create Admin on:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={AdminType}
                    name="radio-buttons-group"
                    onChange={handleChangeRadio}
                    row
                  >
                    {role !== "Company" && (
                      <>
                        <FormControlLabel
                          value="Company"
                          control={<Radio size="small" color="success" />}
                          label="Company"
                        />
                        <FormControlLabel
                          value="OdooCompany"
                          control={<Radio size="small" color="success" />}
                          label="Odoo Company"
                        />
                      </>
                    )}
                    <FormControlLabel
                      value="Sub Company"
                      control={<Radio size="small" color="success" />}
                      label="Sub Company"
                    />
                    <FormControlLabel
                      value="Site"
                      control={<Radio size="small" color="success" />}
                      label="Site"
                    />
                    <FormControlLabel
                      value="auditorSiteId"
                      control={<Radio size="small" color="success" />}
                      label="Auditor Site"
                    />
                    {role !== "Company" && (
                      <FormControlLabel
                        value="None"
                        control={<Radio size="small" color="success" />}
                        label="None"
                      />
                    )}
                  </RadioGroup>
                  <FormHelperText>{RadioHelperText}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <GridGroup>
              {fields?.map((f, i) => {
                if (f.forDesign) {
                  if (AdminType === "None") {
                    return null;
                  } else if (AdminType === "Company") {
                    return (
                      <>
                        <Box key={i}></Box>
                        <Box key={`${i}.${i}`}></Box>
                      </>
                    );
                  } else if (AdminType === "OdooCompany") {
                    return (
                      <>
                        <Box key={i}></Box>
                        <Box key={`${i}.${i}`}></Box>
                      </>
                    );
                  } else return <Box key={i}></Box>;
                }
                if (
                  accountType === "User" &&
                  f.adminType &&
                  f.adminType !== "Company"
                )
                  return null;
                else if (accountType === "Admin" && f.UserType) return null;
                else if (accountType === "Admin") {
                  // if Admin is general => hide (companyName, subCompanyName, siteName) fields
                  if (AdminType === "None" && f.adminType) return null;
                  // if Admin is sub company => hide (siteName) field
                  if (AdminType === "Sub Company" && f.adminType) {
                    if (f.adminType === "Site") return null;
                  }
                  // if Admin is site => hide (subcompany) field
                  if (AdminType === "Site" && f.adminType) {
                    if (f.adminType === "Sub Company") return null;
                  }
                  if (AdminType === "auditorSiteId" && f.adminType) {
                    if (f.adminType === "Sub Company") return null;
                  }

                  // if Admin is company => hide (subCompanyName, siteName) fields
                  if (AdminType === "Company" && f.adminType) {
                    if (f.adminType === "Sub Company") return null;
                    if (f.adminType === "Site") return null;
                  }

                  if (AdminType === "OdooCompany" && f.adminType) {
                    if (f.adminType === "Sub Company") return null;
                    if (f.adminType === "Site") return null;
                  }
                }
                return (
                  <Box key={i}>
                    {f.switch ? (
                      <Box>
                        <FormGroup>
                          <FormLabel
                            component="legend"
                            sx={{
                              mt: theme.dimensions.mt,
                            }}
                          >
                            {f.label}
                          </FormLabel>
                          <FormControlLabel
                            control={
                              <Switch color="success" defaultChecked={true} />
                            }
                            {...register(f?.name)}
                            label="Active"
                          />
                        </FormGroup>
                      </Box>
                    ) : f?.selectExceptionRole === role ? (
                      <Box>
                        <Typography variant="body2" component="h6" gutterBottom>
                          Company Name
                        </Typography>
                        <Box
                          sx={{
                            borderBottom: "1px solid gray",
                            width: "290px",
                            borderRadius: "8px",
                            textAlign: "center",
                          }}
                        >
                          {adminOn?.companyName}
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <TextField
                          inputProps={{
                            ...register(f?.name),
                          }}
                          label={f.label}
                          type={f.type}
                          name={f.name}
                          select={f.select}
                          size="small"
                          error={!!errors && !!errors[f.name]}
                          helperText={errors[f.name]?.message}
                          fullWidth
                          sx={{
                            width: theme.dimensions.width,
                            mt: theme.dimensions.mt,
                            mb: theme.dimensions.mb,
                          }}
                          defaultValue={
                            id &&
                            ((f.name === "companyId"
                              ? employee?.companyId
                              : null) ||
                              (f.name === "employeeId" ? id : null))
                          }
                          {...(f.select && {
                            onChange: (event) =>
                              handleChangeSelect(event, f.name),
                          })}
                          {...(f.select &&
                            f.name === "companyId" &&
                            companyId && {
                              defaultValue: companyId,
                            })}
                          {...(f.select &&
                            f.name !== "companyId" && {
                              disabled: companyId ? false : true,
                            })}
                        >
                          {f.select &&
                            getSelectData(f.name)?.map((item, index) => {
                              const valueMenu = getValueMenu(f.name);
                              return (
                                <MenuItem
                                  key={`${item[valueMenu]}${index}`}
                                  value={item.id}
                                >
                                  {item[valueMenu]}
                                </MenuItem>
                              );
                            })}
                        </TextField>
                      </>
                    )}
                  </Box>
                );
              })}
            </GridGroup>

            <ButtonWrapper>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: theme.color.blackBackground,
                  ml: theme.dimensions.ml,
                  "&:hover": { background: `${theme.color.black}` },
                }}
              >
                {"Add User Account"}
              </Button>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  backgroundColor: theme.color.grayBackground,
                  color: theme.color.dark,
                  "&:hover": { background: `${theme.color.gray50}` },
                }}
              >
                Discard
              </Button>
            </ButtonWrapper>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default React.memo(FormUserAccount);
