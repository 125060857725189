import Layout from "../../layout/Layout";
import { useDashboardStatistics } from "./provider";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import ApartmentTwoTone from "@mui/icons-material/ApartmentTwoTone";
import BusinessTwoTone from "@mui/icons-material/BusinessTwoTone";
import Groups2TwoTone from "@mui/icons-material/Groups2TwoTone";
import { RememberMeTwoTone } from "@mui/icons-material";
import { Bar } from "react-chartjs-2";
import { useAuth } from "../../services/auth";
import { Chart, registerables } from "chart.js";
import { useCompany } from "../Companies/Provider";

Chart.register(...registerables);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F7F9FB",
  padding: 5,
  textAlign: "center",
  color: "#1C1C1C",
}));
const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 250px 250px 250px 250px;
  gap: 10px;
  @media only screen and (max-width: 600px) {
    display: block;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
const Circle = styled.div`
  background-color: gray;
  width: 3rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  @media only screen and (max-width: 600px) {
    margin-left: 25px;
  }
`;
const GridContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  row-gap: 0;
  margin-top: 25px;
`;

function Home() {
  const {
    state: {
      StatisticInfo,
      listSitesSalesDaily,
      listSitesSalesMonthly,
      listSitesSalesWeekly,
      listSitesTicketsDaily,
      listSitesTicketsMonthly,
      listSitesTicketsWeekly,
    },
    fetchOne,
    fetchSitesBySales,
    fetchSitesByTickets,
  } = useDashboardStatistics();
  const { role, adminOn } = useAuth();

  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();

  const [CompanyID, setCompanyID] = useState(0);
  let Statistics = [
    {
      label: "Companies",
      value: "totalCompanies",
      icon: <AccountBalanceTwoToneIcon color="action" fontSize="medium" />,
    },
    {
      label: " Sub Companies",
      value: "totalSubCompanies",
      icon: (
        <ApartmentTwoTone color="action" fontSize="medium"></ApartmentTwoTone>
      ),
    },
    {
      label: " Sites",
      value: "totalSites",
      icon: <BusinessTwoTone color="action" />,
    },
    {
      label: " Employees",
      value: "totalEmployees",
      icon: <Groups2TwoTone color="action" />,
    },
  ];
  useEffect(() => {
    if (role !== "Auditor") {
      fetchOne(CompanyID);
    }
  }, [CompanyID]);
  useEffect(() => {
    if (role === "Admin") {
      fetchCompany();
    }
  }, []);

  const handleChange = (event) => {
    setCompanyID(event.target.value);
  };
  /** Monthly Sales */
  const labelsSalesMonthly = listSitesSalesMonthly?.map((s) => s.site.siteName);
  const dataSalesMonthly = {
    labels: labelsSalesMonthly,
    datasets: [
      {
        label: "Monthly top 10 sites income",
        backgroundColor: "gray",
        borderColor: "gray",
        data: listSitesSalesMonthly?.map((s) => s.totalAmount),
      },
    ],
  };
  /** Weekly Sales */
  const labelsSalesWeekly = listSitesSalesWeekly?.map((s) => s.site.siteName);
  const dataSalesWeekly = {
    labels: labelsSalesWeekly,
    datasets: [
      {
        label: "Weekly top 10 sites income",
        backgroundColor: "gray",
        borderColor: "gray",
        data: listSitesSalesWeekly?.map((s) => s.totalAmount),
      },
    ],
  };
  /** Daily Sales */
  const labelsSalesDaily = listSitesSalesDaily?.map((s) => s.site.siteName);
  const dataSalesDaily = {
    labels: labelsSalesDaily,
    datasets: [
      {
        label: "Daily top 10 sites income",
        backgroundColor: "gray",
        borderColor: "gray",
        data: listSitesSalesDaily?.map((s) => s.totalAmount),
      },
    ],
  };

  /** Monthly Tickets */
  const labelsTicketMonthly = listSitesTicketsMonthly?.map(
    (s) => s.site.siteName
  );
  const dataTicketMonthly = {
    labels: labelsTicketMonthly,
    datasets: [
      {
        label: "Monthly top 10 sites cars",
        backgroundColor: "gray",
        borderColor: "gray",
        data: listSitesTicketsMonthly?.map((s) => s.ticketCount),
      },
    ],
  };
  /** Weekly Tickets */
  const labelsTicketWeekly = listSitesTicketsWeekly?.map(
    (s) => s.site.siteName
  );
  const dataSitesTicketWeekly = {
    labels: labelsTicketWeekly,
    datasets: [
      {
        label: "Weekly top 10 sites cars",
        backgroundColor: "gray",
        borderColor: "gray",
        data: listSitesTicketsWeekly?.map((s) => s.ticketCount),
      },
    ],
  };
  /** Daily Ticket */
  const labelsTicketDaily = listSitesTicketsDaily?.map((s) => s.site.siteName);
  const dataTicketDaily = {
    labels: labelsTicketDaily,
    datasets: [
      {
        label: "Daily top 10 sites cars",
        backgroundColor: "gray",
        borderColor: "gray",
        data: listSitesTicketsDaily?.map((s) => s.ticketCount),
      },
    ],
  };
  return (
    <Layout>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: "flex" }}>
          {StatisticInfo?.totalCompanies !== null && (
            <Box mb={3} mr={2} width={500}>
              <Item>
                <Title>
                  <Title>
                    <AccountBalanceTwoToneIcon
                      color="action"
                      fontSize="medium"
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "gray", ml: 1 }}
                    >
                      Companies
                    </Typography>
                  </Title>
                  <Circle>
                    <Typography variant="subtitle2" gutterBottom>
                      {StatisticInfo?.totalCompanies}
                    </Typography>
                  </Circle>
                </Title>
              </Item>
            </Box>
          )}
          {StatisticInfo?.totalUsers !== null && (
            <Box mb={3} width={500}>
              <Item>
                <Title>
                  <Title>
                    <RememberMeTwoTone color="action" fontSize="medium" />
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "gray", ml: 1 }}
                    >
                      Users
                    </Typography>
                  </Title>
                  <Circle>
                    <Typography variant="subtitle2" gutterBottom>
                      {StatisticInfo?.totalUsers}
                    </Typography>
                  </Circle>
                </Title>
              </Item>
            </Box>
          )}
        </Box>
        {role === "Admin" && (
          <Box mt={2}>
            <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
              <Select
                value={CompanyID}
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                  autoFocus: true,
                }}
                sx={{
                  fontSize: "12px",
                  width: "300px",
                  height: "40px",
                  marginTop: "14px",
                }}
              >
                <MenuItem value={0}>
                  <em>All</em>
                </MenuItem>
                {companies?.map((company) => {
                  return (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}

        {role === "Company" && (
          <>
            <Box mb={10}>
              <span style={{ fontSize: "12px" }}>Company Name</span>
              {/* Adding sub company => !subcompany */}
              <Box
                sx={{
                  borderBottom: "1px solid gray",
                  width: "290px",
                  borderRadius: "8px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {adminOn?.companyName}
              </Box>
            </Box>
          </>
        )}

        <GridGroup>
          {Statistics?.map(
            (item, index) =>
              StatisticInfo?.[item?.value] !== null &&
              item.label !== "Companies" && (
                <Item key={index}>
                  <Title>
                    <Title>
                      {item.icon}
                      <Typography
                        variant="subtitle2"
                        sx={{ color: "gray", ml: 1 }}
                      >
                        {item.label}
                      </Typography>
                    </Title>
                    <Circle>
                      <Typography variant="subtitle2" gutterBottom>
                        {StatisticInfo?.[item.value]}
                      </Typography>
                    </Circle>
                  </Title>
                </Item>
              )
          )}
        </GridGroup>
      </Box>

      {role === "Company" || role === "Admin" ? (
        <>
          {/** labels */}
          <Box mt={3}>
            <Typography variant="subtitle2" sx={{ color: "gray" }}>
              Monthly
            </Typography>
            <GridContent>
              <div style={{ width: "500px", height: "250px" }}>
                <Bar data={dataSalesMonthly} />
              </div>
              <div style={{ width: "500px", height: "250px" }}>
                <Bar data={dataTicketMonthly} />
              </div>
            </GridContent>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ color: "gray" }}>
              Weekly
            </Typography>
            <GridContent>
              <div style={{ width: "500px", height: "250px" }}>
                <Bar data={dataSalesWeekly} />
              </div>
              <div style={{ width: "500px", height: "250px" }}>
                <Bar data={dataSitesTicketWeekly} />
              </div>
            </GridContent>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ color: "gray" }}>
              Daily
            </Typography>
            <GridContent>
              <div style={{ width: "500px", height: "250px" }}>
                <Bar data={dataSalesDaily} />
              </div>
              <div style={{ width: "500px", height: "250px" }}>
                <Bar data={dataTicketDaily} />
              </div>
            </GridContent>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
}

export default Home;
