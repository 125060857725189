const fixedFields = [
  {
    name1: "company",
    name: "companyName",
    label: "Company Name",
    disable: true,
    value: "companyName",
  },
  {
    name1: "site",
    name: "siteName",
    label: "Site Name",
    required: true,
    disable: true,
    validationType: "number",
  },
  {
    name: "title",
    label: "Zone Name",
    disable: true,
  },
  {
    name: "title",
    label: "Service Name",
  },
  {
    name: "serviceTypeId",
    label: "Service Type",
    select: true,
    contentName: "name",
    validationType: "number",
    data: "name",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "isActive",
    label: "Service Status",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "freePeriod",
    label: "Free Period",
    type: "number",
  },
  {
    name: "fixPriceTrial",
    label: "Fix Price Trial",
  },
  {
    name: "fixPriceOfficial",
    label: "Fix Price Official",
  },
  {
    name: "withFatoora",
    label: "Zetca",
    switch: true,
    validationType: "boolean",
    validations: [
      {
        type: "required",
        params: ["this field is required"],
      },
    ],
  },
  {
    name: "printDivision1",
    label: "Print Division 1",
    type: "number",
    section: true,
    printDivision: true,
  },
  {
    name: "printDivision2",
    label: "Print Division 2",
    type: "number",
    section: true,
    printDivision: true,
  },
  {
    name: "printDivision3",
    label: "Print Division 3",
    type: "number",
    section: true,
    printDivision: true,
  },
  {
    name: "printDivision4",
    label: "Print Division 4",
    type: "number",
    section: true,
    printDivision: true,
  },
];

export default fixedFields;
