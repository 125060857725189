import { Box, Button, Card, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import styled from "styled-components";
import fixedFields from "./FixedFields";
import { useNavigate, useParams } from "react-router-dom";
import ModalBox from "../../../ui/ModalBox";
import { useService } from "../Provider";
import { useZone } from "../../Zones/Provider";
//icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 1;
  padding-left: 20px;
  padding-top: 20px;
`;

const DetailFixedService = () => {
  let theme = useTheme();
  let navigate = useNavigate();

  const { zid, szid } = useParams();
  const [open, setOpen] = useState(false);

  const {
    state: { selected: service },
    fetchOne: fetchService,
    remove,
  } = useService();
  const {
    state: { selected: Zone },
    fetchOne: fetchZone,
  } = useZone();

  useEffect(() => {
    fetchService(szid);
  }, [fetchService, szid]);

  //fetch Zones , for breadcrumb and get data when refresh
  useEffect(() => {
    fetchZone(zid);
  }, [fetchZone, zid]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async (ID) => {
    try {
      await remove(ID);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  if (!service || !Zone) return;
  return (
    <>
      <Typography variant="Poster" component="h4">
        Service Details
      </Typography>
      <Paper
        elevation={0}
        sx={{
          p: theme.dimensions.padding,
          pb: theme.dimensions.padding * 2,
          mt: theme.dimensions.mt,
          mb: theme.dimensions.mb,
          background: theme.color.PaperbgColor,
          width: "90%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="Poster" component="h4" gutterBottom>
            Service Information
          </Typography>

          <Button
            variant="contained"
            style={{ background: "#FF4747" }}
            onClick={() => setOpen(true)}
          >
            Delete
          </Button>
        </Box>
        <Grid2>
          {fixedFields?.map((f, index) => {
            return (
              <>
                {!f.printDivision && f.disable && (
                  <>
                    <span
                      key={index}
                      style={{ color: theme.color.PaperlabelColor }}
                    >
                      {f.label}
                    </span>
                    <span>
                      {f.name1 ? Zone?.[f.name1]?.[f.name] : Zone?.[f.name]}
                    </span>
                  </>
                )}

                {!f.printDivision && !f.disable && (
                  <>
                    <span
                      key={index}
                      style={{ color: theme.color.PaperlabelColor }}
                    >
                      {f.label}
                    </span>

                    {f?.name === "isActive" ? (
                      service?.isActive ? (
                        <span style={{ color: "#4AA785" }}>
                          <FiberManualRecordIcon
                            fontSize="small"
                            sx={{ fontSize: 10, mr: 0.2 }}
                          />
                          Active
                        </span>
                      ) : (
                        <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          <FiberManualRecordIcon
                            fontSize="small"
                            sx={{ fontSize: 10, mr: 0.2 }}
                          />
                          Inactive
                        </span>
                      )
                    ) : (
                      <>
                        {f.name === "withFatoora" ? (
                          service?.withFatoora ? (
                            <span style={{ color: "#4AA785" }}>
                              <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ fontSize: 10, mr: 0.2 }}
                              />
                              Active
                            </span>
                          ) : (
                            <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                              <FiberManualRecordIcon
                                fontSize="small"
                                sx={{ fontSize: 10, mr: 0.2 }}
                              />
                              Inactive
                            </span>
                          )
                        ) : (
                          <span> {service?.[f?.name]}</span>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
          })}
        </Grid2>
        <Grid2>
          {fixedFields?.map((f, index) => {
            return (
              <>
                {f.printDivision && (
                  <Card
                    key={index}
                    sx={{
                      p: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ mr: 2, color: theme.color.PaperlabelColor }}
                    >
                      {f.label}
                    </Typography>
                    <div
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "100%",
                        backgroundColor: theme.color.PaperbgColor,
                        padding: "10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography>{service?.[f?.name]}</Typography>
                      <Typography>times</Typography>
                    </div>
                  </Card>
                )}
              </>
            );
          })}
        </Grid2>
      </Paper>
      <ModalBox
        open={open}
        operation="delete"
        handleClose={handleClose}
        handleOperation={() => {
          handleDelete(szid);
          setOpen(false);
        }}
        text={`Do you want to complete the process of deleting all information?`}
      />
    </>
  );
};

export default DetailFixedService;
