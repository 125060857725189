import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import CompanyProvider from "../view/Companies/Provider";
import SubCompanyProvider from "../view/SubCompanies/Provider";
import themeOptions from "../theme";
import EmployeesProvider from "../view/employee/Provider";
import SiteProvider from "../view/Sites/Provider";
import ContractProvider from "../view/Contracts/Provider";
import GateProvider from "../view/Gates/Provider";
import DisCountProvider from "../view/DisCounts/Provider";
import ZoneProvider from "../view/Zones/Provider";
import ServiceProvider from "../view/Services/Provider";
import MemberPlansProvider from "../view/MemberPlans/Provider";
import UserAccountProvider from "../view/UserAccount/Provider";
import MemberShipProvider from "../view/MemberShip/Provider";
import CarProvider from "../view/Car/Provider";
import PayrollProvider from "../view/Payroll/Provider";
import AttendanceProvider from "../view/Attendance/Provider";
import ReportsProvider from "../view/Reports/Provider";
import TemplateMessProvider from "../view/TemplateMessage/Provider";
import VoidReasonProvider from "../view/VoidReason/Provider";
import SmsProviders from "../view/SMS/Sms";
import SMSProvider from "../view/SMS/Provider";
import WebContentProvider from "../view/WebContent/provider";
import SecretKeysProvider from "../view/SecretKeys/provider";
import WhiteIpsProvider from "../view/WhiteIps/provider";
import DeductionProvider from "../view/Deductions/provider";
import StoreProvider from "../view/Store/Provider";
import VendorProvider from "../view/Store/Vendors/Provider";
import MaterialCategoryProvider from "../view/Store/Categories/Provider";
import MaterialProvider from "../view/Store/Materials/Provider";
import DashboardStatisticsProvider from "../view/home/provider";
import MemoryProvider from "../view/Store/Memo/Provider";
import OdooInvoicesProvider from "../view/OdooInvoices/provider";

const theme = createTheme(themeOptions);

export default function Providers({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <UtilsProvider>
        <AuthProvider>
          <CompanyProvider>
            <SubCompanyProvider>
              <EmployeesProvider>
                <SiteProvider>
                  <ContractProvider>
                    <GateProvider>
                      <DisCountProvider>
                        <ZoneProvider>
                          <ServiceProvider>
                            <TemplateMessProvider>
                              <VoidReasonProvider>
                                <MemberPlansProvider>
                                  <UserAccountProvider>
                                    <MemberShipProvider>
                                      <CarProvider>
                                        <AttendanceProvider>
                                          <PayrollProvider>
                                            <ReportsProvider>
                                              <SMSProvider>
                                                <WebContentProvider>
                                                  <SecretKeysProvider>
                                                    <WhiteIpsProvider>
                                                      <DeductionProvider>
                                                        <StoreProvider>
                                                          <VendorProvider>
                                                            <MaterialCategoryProvider>
                                                              <MaterialProvider>
                                                                <DashboardStatisticsProvider>
                                                                  <MemoryProvider>
                                                                    <OdooInvoicesProvider>
                                                                      {children}
                                                                    </OdooInvoicesProvider>
                                                                  </MemoryProvider>
                                                                </DashboardStatisticsProvider>
                                                              </MaterialProvider>
                                                            </MaterialCategoryProvider>
                                                          </VendorProvider>
                                                        </StoreProvider>
                                                      </DeductionProvider>
                                                    </WhiteIpsProvider>
                                                  </SecretKeysProvider>
                                                </WebContentProvider>
                                              </SMSProvider>
                                            </ReportsProvider>
                                          </PayrollProvider>
                                        </AttendanceProvider>
                                      </CarProvider>
                                    </MemberShipProvider>
                                  </UserAccountProvider>
                                </MemberPlansProvider>
                              </VoidReasonProvider>
                            </TemplateMessProvider>
                          </ServiceProvider>
                        </ZoneProvider>
                      </DisCountProvider>
                    </GateProvider>
                  </ContractProvider>
                </SiteProvider>
              </EmployeesProvider>
            </SubCompanyProvider>
          </CompanyProvider>
        </AuthProvider>
      </UtilsProvider>
    </ThemeProvider>
  );
}
