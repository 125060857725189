import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRequest } from "./request";
import { useSite } from "../view/Sites/Provider";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  let [user, setUser] = useState(localStorage.getItem("user"));
  // let [blockexplorerurl, setblockexplorerurl] = useState(JSON.parse(localStorage.getItem("blockChainUrl")))
  let [role, setRole] = useState("Unknown");
  let [adminOn, setAdminOn] = useState(null);

  const req = useRequest();

  useEffect(() => {
    if (user?.roles?.find((element) => element === "Company")) {
      setRole("Company");
      setAdminOn(user?.company);
    } else if (user?.roles?.find((element) => element === "Site")) {
      setRole("Site");
      setAdminOn(user?.site);
    } else if (user?.roles?.find((element) => element === "SubCompany")) {
      setRole("SubCompany");
      setAdminOn(user?.subCompany);
    } else if (user?.roles?.find((element) => element === "Auditor")) {
      setRole("Auditor");
      setAdminOn(user?.site);
    } else {
      setRole("Admin");
    }
  }, [user, setRole, setAdminOn]);

  useEffect(() => {
    if (user) return;
    const localUser = localStorage.getItem("user");
    if (localUser) setUser(JSON.parse(localStorage.getItem("user")));
  }, [user]);
  // let isVerified = useCallback(() => {
  //   return user && user.tokens;
  // }, [user]);
  const saveTokens = useCallback(
    (tokens) => {
      window.localStorage.setItem("user", JSON.stringify(...user));
      window.localStorage.setItem("tokens", JSON.stringify(...tokens));
      // console.log(localStorage, "localStorage");
    },
    [user]
  );

  let signin = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`Auth/login`, data);
          window.localStorage.setItem("user", JSON.stringify(res.data.user));
          window.localStorage.setItem(
            "tokens",
            JSON.stringify({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
              expiration: res.data.expiration,
              issuedAt: res.data.issuedAt,
              localIssueAt: Date.now(),
            })
          );
          // console.log(res, "res");
          setUser(res.data.user);
          saveTokens(res);
          return;
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let createUser = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        // console.log(data);
        try {
          const res = await req(`Auth/createUser`, data);
          resolve();
          // console.log(res);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );
  let verifyEmail = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          // const res = await req(`Auth/verifyEmail`, data);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let signout = useCallback(() => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("tokens");
      window.localStorage.removeItem("user");
      setUser(null);
      resolve();
    });
  }, []);

  let logout = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await req(`Auth/logout`, {}, {}, true);
        window.localStorage.removeItem("tokens");
        window.localStorage.removeItem("user");
        window.location.href = "/";
        setUser(null);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  //reset password
  let resetPass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`Auth/password/reset`, data);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let confirmresetPass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`Auth/password/reset/confirm`, data);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );
  let changepass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`Auth/password/change`, data, {}, true);
          // console.log(res);

          resolve(res);
        } catch (error) {
          console.log(error);

          reject(error);
        }
      });
    },
    [req]
  );

  let value = {
    user,
    role,
    adminOn,
    signin,
    signout,
    createUser,
    verifyEmail,
    resetPass,
    confirmresetPass,
    changepass,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  const location = useLocation();
  let { user } = useAuth();

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}
