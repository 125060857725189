import React from "react";
// Hooks
import { useNavigate } from "react-router-dom";
// material ui
import { Box, Typography, Modal, Button, useTheme } from "@mui/material";
// MUI Icons
// add
import LibraryAddTwoToneIcon from "@mui/icons-material/LibraryAddTwoTone";
// edit
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
// delete
import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";
// Paying
import PriceCheckTwoToneIcon from "@mui/icons-material/PriceCheckTwoTone";
// Success
import FileDownloadDoneTwoToneIcon from "@mui/icons-material/FileDownloadDoneTwoTone";
import RoleRateForm from "../view/employee/InfoEmployee/RolerateForm";
import EmployeeZonesForm from "../view/employee/InfoEmployee/EmployeeZonesForm";
import AddAllEmployees from "./tableContainer/AddAllEmployees";
// icons
// import Add_Done from "../icons/Add_Done.svg";
// import Delete_Pop from "../icons/Delete_Pop.svg";
// import Edit_Icon from "../icons/Edit_Icon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 480,
  borderRadius: "8px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
};

const getIcon = (name) => {
  switch (name) {
    case "add":
      return LibraryAddTwoToneIcon;
    case "edit":
      return BorderColorTwoToneIcon;
    case "delete":
      return CancelPresentationTwoToneIcon;
    case "pay":
      return PriceCheckTwoToneIcon;
    case "success":
      return FileDownloadDoneTwoToneIcon;
    default:
      return LibraryAddTwoToneIcon;
  }
};

const ModalBox = ({
  open = false,
  handleClose = () => {},
  handleOperation = () => {},
  operation = "",
  text = "",
  Send = false,
  btnLabel = "Done, Close it",
  navigateLink = null,
  navigateMessage = null,
  addRate = false,
  existSite = false,
  employee,
  editRoleZoneEmp = false,
  row,
  roleId,
  setRoleId,
}) => {
  const handleFunction = () => {
    handleOperation();
    handleClose();
  };

  let theme = useTheme();
  const navigate = useNavigate();
  const CurrentIcon = getIcon(operation);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {editRoleZoneEmp ? (
          <>
            <EmployeeZonesForm
              existSite={existSite}
              roleId={roleId}
              setRoleId={setRoleId}
              row={row}
              open={open}
              handleEditRateClose={handleClose}
            />
          </>
        ) : (
          <>
            {addRate ? (
              <>
                <RoleRateForm
                  existSite={existSite}
                  employee={employee}
                  open={open}
                  handleAddRateClose={handleClose}
                />
              </>
            ) : (
              <>
                {Send ? (
                  <AddAllEmployees handleClose={handleClose} site={row} />
                ) : (
                  <>
                    <CurrentIcon sx={{ fontSize: "170px", mb: 5 }} />
                    <Typography
                      id="modal-modal-description"
                      sx={{ textAlign: "center", fontWeight: 600, mb: 2 }}
                    >
                      {text}{" "}
                    </Typography>
                    {navigateLink &&
                      navigateLink?.map((link, i) => (
                        <Typography
                          id="modal-modal-description"
                          sx={{
                            textAlign: "center",
                            fontWeight: 600,
                            // mb: 2,
                            color: "#95A4FC",
                            "&:hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => navigate(link)}
                        >
                          {navigateMessage[i]}
                        </Typography>
                      ))}
                    <Box sx={{ width: "100%" }}>
                      <Button
                        onClick={handleFunction}
                        sx={{
                          background: theme.color.blackBackground,
                          color: "white",
                          width: "100%",
                          mb: 1.3,
                          "&:hover": { background: `${theme.color.black}` },
                        }}
                      >
                        {btnLabel}
                      </Button>

                      {operation === "delete" ||
                        (operation === "pay" && (
                          <Button
                            onClick={handleClose}
                            sx={{
                              background: theme.color.gray200,
                              color: "black",
                              width: "100%",
                              "&:hover": {
                                background: `${theme.color.gray300}`,
                              },
                            }}
                          >
                            Cancel
                          </Button>
                        ))}
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default React.memo(ModalBox);
