import React from "react";

const Status = ({ row }) => {
  const getStatus = (statusId) => {
    var status;
    switch (statusId) {
      case 0:
        status = "New";
        break;
      case 1:
        status = "Under Processing";
        break;
      case 2:
        status = "Failure";
        break;
      case 3:
        status = "Success";
        break;
      default:
        status = "New";
        break;
    }
    return status;
  };
  return <div>{getStatus(row.statusId)}</div>;
};

export default Status;
