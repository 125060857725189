//react component
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//context we need
import { useCompany } from "../Companies/Provider";
import { useSite } from "./Provider";

//mui material component
import {
  Select,
  MenuItem,
  FormControl,
  Box,
  Alert,
  Typography,
  TextField,
  Button,
  useTheme,
} from "@mui/material";
//ui component
import CustomTable from "../../ui/CustomTable";
import TabsList from "../../ui/TabsList";
import ActiveCell from "../Companies/CompanyTable/ActiveCell";
import { CalculateCountArray } from "../../utilities/functions";
import styled from "styled-components";
import { useAuth } from "../../services/auth";
import CreatedDate from "../SubCompanies/CreatedDate";
import { useSubCompany } from "../SubCompanies/Provider";
import { FilterList } from "@mui/icons-material";
import { useForm } from "react-hook-form";

// Yup
import { yupResolver } from "@hookform/resolvers/yup";
// Yup
import * as Yup from "yup";
const GridGroup1 = styled.div`
  width: fit-content;
  display: grid;
  padding: 20px;
  padding-bottom: 40px;
  margin-bottom: 10px;
  grid-template-columns: 325px 325px 325px;
  gap: 10px;
  grid-template-rows: 75px;
  grid-column-gap: 10px;
`;
const formSchema = Yup.object().shape({
  cid: Yup.number(),
  scid: Yup.number(),
  search: Yup.string(),
});

const ListSites = () => {
  let theme = useTheme();
  let navigate = useNavigate();
  // providers
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const [search, setSearch] = useState("");
  const {
    state: { Names: companies },
    fetchNames: fetchCompany,
  } = useCompany();
  const {
    state: { Names: subCompanies },
    fetchNames: fetchSubCompaniesforCompany,
  } = useSubCompany();
  const {
    state: { list: sites, page, perPage, count, totalSize, filter },
    fetchList: fetchSites,
    dispatch,
  } = useSite();
  const [selectedCompany, setSelectedCompany] = React.useState(
    filter.companyId
  );
  const [selectedSubCompany, setSelectedSubCompany] = React.useState(
    filter.subCompanyId
  );
  const { role, adminOn } = useAuth();
  const [error, setError] = useState(null);
  const getCols = (name) => {
    switch (name) {
      case "Sites":
        return [
          {
            label: "Site Id",
            name: "id",
            headTextLeft: true,
            textLeft: true,
          },
          {
            label: "Site Name",
            name: "siteName",
          },
          { label: "Site Country", name: "country" },
          {
            label: "Employees Number",
            name: "employees",
            customRender: CalculateCountArray,
          },
          {
            label: "Date Added",
            name: "createdDate",
            customRender: CreatedDate,
          },
          { label: "Site Status", name: "isActive", customRender: ActiveCell },
        ];
    }
  };

  const getData = (name) => {
    switch (name) {
      case "Sites":
        return sites;
    }
  };

  //useEffect to fetch companies list
  useEffect(() => {
    if (role === "Admin") {
      if (companies?.length === 0) fetchCompany();
    }
  }, [fetchCompany]);

  //useEffect to fetch subCompanies list
  useEffect(() => {
    if (role === "Admin" && selectedCompany) {
      fetchSubCompaniesforCompany(selectedCompany);
    } else if (role === "Company") {
      fetchSubCompaniesforCompany(adminOn?.id);
    }
  }, [fetchSubCompaniesforCompany, selectedCompany]);

  //useEffect to fetch default value
  useEffect(() => {
    if (role === "Admin") {
      fetchSites(0, page, perPage, 0, "");
    } else if (role === "Company") {
      fetchSites(adminOn?.id, page, perPage, 0, "");
    } else if (role === "SubCompany") {
      fetchSites(0, page, perPage, adminOn?.id, "");
    }
  }, []);
  //useEffect for pagination
  useEffect(() => {
    if (role === "Admin") {
      fetchSites(
        getValues("cid"),
        page,
        perPage,
        getValues("scid"),
        getValues("search")
      );
    } else if (role === "Company") {
      fetchSites(
        adminOn?.id,
        page,
        perPage,
        getValues("scid"),
        getValues("search")
      );
    } else if (role === "SubCompany") {
      fetchSites(0, page, perPage, adminOn?.id, getValues("search"));
    }
  }, [fetchSites, page, perPage, role]);

  const tabs = [{ title: "Sites", num: totalSize }];

  const [selectedCols, setSelectedCols] = useState("Sites");

  //handle detail click
  const handleDetail = (ID) => {
    navigate(`SiteDetail/${ID}`);
  };

  // handle change selected company
  const handleChangeCompanyId = (event) => {
    // console.log(event.target, "cid");
    if (role === "Admin") {
      dispatch({
        type: "set_filter",
        payload: { companyId: event.target.value, subCompanyId: 0 },
      });
      setError(null);
      setSelectedCompany(event.target.value);
    }
  };
  // handle change selected subcompany
  const handleChangeSubCompanyId = (event) => {
    // console.log(event.target, "sid");
    if (role === "Admin") {
      dispatch({
        type: "set_filter",
        payload: {
          companyId: filter.companyId,
          subCompanyId: event.target.value,
        },
      });
      setSelectedSubCompany(event.target.value);
    } else if (role === "Company") {
      dispatch({
        type: "set_filter",
        payload: {
          companyId: adminOn?.id,
          subCompanyId: event.target.value,
        },
      });
      setSelectedSubCompany(event.target.value);
    }
  };

  // handle change page size
  const changePageSize = (size) => {
    dispatch({ type: "set_per_page", payload: size });
  };

  const onSubmit = async (values) => {
    try {
      if (role === "Admin") {
        await fetchSites(values.cid, page, perPage, values.scid, values.search);
      } else if (role === "Company") {
        await fetchSites(
          adminOn?.id,
          page,
          perPage,
          values.scid,
          values.search
        );
      } else if (role === "SubCompany") {
        await fetchSites(0, page, perPage, adminOn?.id, values.search);
      }
    } catch (error) {
      console.log("error");
    }
  };
  if (!companies || !sites) return;

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {role === "Admin" && (
        <>
          <Typography variant="Poster" component="h4" mb={2}>
            Select Company or Sub Company
          </Typography>
          <Box>
            <Box sx={{ background: "#f7f9fb", width: "1000px" }}>
              {error && (
                <Alert
                  severity="error"
                  sx={{ minWidth: "330px", maxWidth: "fit-content" }}
                >
                  {error}
                </Alert>
              )}
              <GridGroup1>
                <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
                  <span style={{ fontSize: "12px" }}>Company Name</span>
                  <Select
                    value={selectedCompany}
                    onChange={handleChangeCompanyId}
                    displayEmpty
                    inputProps={{
                      ...register("cid"),

                      "aria-label": "Without label",
                      autoFocus: true,
                    }}
                    sx={{
                      fontSize: "12px",
                      width: "300px",
                      height: "40px",
                      marginTop: "14px",
                    }}
                  >
                    <MenuItem value={0}>
                      <em>ALL</em>
                    </MenuItem>
                    {companies.map((company) => {
                      return (
                        <MenuItem key={company.id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {subCompanies.length > 0 && selectedCompany !== 0 && (
                  <FormControl
                    sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}
                  >
                    <span style={{ fontSize: "12px" }}>Sub Company Name</span>
                    <Select
                      value={selectedSubCompany}
                      onChange={handleChangeSubCompanyId}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                        ...register("scid"),

                        autoFocus: true,
                      }}
                      sx={{
                        fontSize: "12px",
                        width: "300px",
                        height: "40px",
                        marginTop: "14px",
                      }}
                    >
                      <MenuItem value={0}>
                        <em>ALL</em>
                      </MenuItem>
                      {subCompanies.map((subCompany) => {
                        return (
                          <MenuItem key={subCompany.id} value={subCompany.id}>
                            {subCompany.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                <Box>
                  <FormControl sx={{ minWidth: 120, marginBottom: "30px" }}>
                    <span style={{ fontSize: "12px" }}>Search</span>
                    <TextField
                      inputProps={{
                        ...register("search"),
                      }}
                      sx={{ marginTop: "20px" }}
                      size="small"
                    />
                  </FormControl>
                </Box>
              </GridGroup1>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: theme.color.blackBackground,
                    color: "white",
                    mr: 5,
                    mb: 2,
                    "&:hover": { background: `${theme.color.blackBackground}` },
                    textTransform: "capitalize",
                  }}
                >
                  <FilterList />
                  <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {role === "Company" && (
        <Box sx={{ background: "#f7f9fb", width: "1000px" }}>
          <GridGroup1>
            <Box mb={10}>
              <span style={{ fontSize: "12px" }}>Company Name</span>
              {/* Adding sub company => !subcompany */}
              <Box
                sx={{
                  borderBottom: "1px solid gray",
                  width: "290px",
                  borderRadius: "8px",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                {adminOn?.companyName}
              </Box>
            </Box>
            {subCompanies.length > 0 && (
              <FormControl sx={{ m: 1, minWidth: 120, marginBottom: "20px" }}>
                <span style={{ fontSize: "12px" }}>Sub Company Name</span>
                <Select
                  value={selectedSubCompany}
                  onChange={handleChangeSubCompanyId}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                    autoFocus: true,
                    ...register("scid"),
                  }}
                  sx={{
                    fontSize: "12px",
                    width: "300px",
                    height: "40px",
                    marginTop: "14px",
                  }}
                >
                  <MenuItem value={0}>
                    <em>ALL</em>
                  </MenuItem>
                  {subCompanies.map((subCompany) => {
                    return (
                      <MenuItem key={subCompany.id} value={subCompany.id}>
                        {subCompany.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <Box>
              <FormControl sx={{ minWidth: 120, marginBottom: "30px" }}>
                <span style={{ fontSize: "12px" }}>Search</span>
                <TextField
                  inputProps={{
                    ...register("search"),
                  }}
                  sx={{ marginTop: "20px" }}
                  size="small"
                />
              </FormControl>
            </Box>
          </GridGroup1>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                color: "white",
                mr: 5,
                mb: 2,
                "&:hover": { background: `${theme.color.blackBackground}` },
                textTransform: "capitalize",
              }}
            >
              <FilterList />
              <span style={{ margin: theme.spacing(0, 2) }}>Filter</span>
            </Button>
          </Box>
        </Box>
      )}
      {role === "SubCompany" && (
        <GridGroup1>
          <Box mb={10}>
            <span style={{ fontSize: "12px" }}>Sub-Company Name</span>
            <Box
              sx={{
                borderBottom: "1px solid gray",
                width: "290px",
                borderRadius: "8px",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              {adminOn?.subCompanyName}
            </Box>
          </Box>
        </GridGroup1>
      )}
      <div>
        <TabsList
          tabs={tabs}
          setSelectedCols={setSelectedCols}
          selectedCols={selectedCols}
        ></TabsList>
      </div>
      <CustomTable
        empty={sites?.length === 0 ? true : false}
        cols={getCols(selectedCols)}
        data={getData(selectedCols)}
        filters={{
          pagination: true,
          page: perPage,
          changePageSize: changePageSize,
          add:
            (role === "Admin" &&
              (() => {
                selectedCompany === 0
                  ? setError("please select one company")
                  : navigate(`/sites/add/${selectedCompany}`);
              })) ||
            (role === "Company" &&
              (() => {
                navigate(`/sites/add/${adminOn?.id}`);
              })),
        }}
        moreOptions={
          role !== "SubCompany" && {
            detail: handleDetail,
          }
        }
        page={page}
        showButton={true}
        rowsPerPage={10}
        dispatch={dispatch}
        count={count}
      ></CustomTable>
    </form>
  );
};

export default React.memo(ListSites);
